import FormGroup from "../../form-group"

interface Props {
	credentials: SecondaryAdminRegisterInformation,
	setCredentials: (credentials: SecondaryAdminRegisterInformation) => void,
}

export default function UsernameInput (props: Props) {
	const { credentials, setCredentials } = props

	return (
		<FormGroup
			label = "Username"
			type = "username"
			placeholder = "tigerKing123"
			onChange = {(event) => setCredentials({...credentials, username: event.target.value})}
			required
			value = {credentials.username || ""}
		/>
	)
}
