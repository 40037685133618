import _ from "lodash"
import { useContext, useEffect } from "react"
import UsersClass from "../../classes/users-class"
import { isErrorResponse } from "../../utils/type-checks"
import AppContext from "../../contexts/eventfull-it-context"

export default function useRetrieveUsers(): void {
	const appContext = useContext(AppContext)

	useEffect(() => {
		if (
			_.isNull(appContext.eventfullApiClient.httpClient.accessToken) ||
			!_.isEmpty(appContext.usersData?.usersMap)
		) return
		void retrieveUsers()
	}, [appContext.eventfullApiClient.httpClient.accessToken])

	const retrieveUsers = async (): Promise<void> => {
		try {
			const response = await appContext.eventfullApiClient.usersDataService.getUsersEvents()

			if (!_.isEqual(response.status, 200) || isErrorResponse(response.data)) {
				console.error("Error retrieving users:", response)
				return
			}

			if (_.isNull(appContext.usersData)) appContext.usersData = new UsersClass()
			for (const user of response.data.users) {
				appContext.usersData.addUser(user)
			}
		} catch (error) {
			console.error("Error retrieving users:", error)
		}
	}
}
